(function(w, d) {
    w.tooltip_config.forEach(function(o) {
        var oBlockID = d.getElementById('tncms-block-' + o.block_id),
            oToolTip = oBlockID.querySelector('.popover'),
            sMarginTop = oBlockID.querySelector('.btn').clientHeight;      
        if (typeof(Storage) !== "undefined") {
            if(localStorage.getItem('lastButtonAppearence-' + o.block_id) == null){
                window.setTimeout(function() {
                    oToolTip.setAttribute("style", 'top:' + sMarginTop + 'px;');
                    oToolTip.classList.add('visible');
                    d.addEventListener("click", function(){
                    	oToolTip.classList.remove('visible');
                    	oToolTip.classList.add('hidden');
                    }, false);
                    localStorage.setItem('lastButtonAppearence-' + o.block_id,(new Date()).getTime());
                }, 500);
            }
            var clearTime = 1000 * 60 * 60 * o.display_again,
                lastclear = localStorage.getItem('lastButtonAppearence-' + o.block_id),
                time_now  = (new Date()).getTime();
            if ((time_now - lastclear) > clearTime) {
                localStorage.removeItem('lastButtonAppearence-' + o.block_id);
            }
        } else {
            return false
        }
        oBlockID.addEventListener("mouseenter", function() {
            setTimeout(function() {
              oToolTip.classList.remove('hidden'); 
              oToolTip.setAttribute("style", 'top:' + sMarginTop + 'px;');
              oToolTip.classList.add('visible');
            }, 250)
        }, false)
        oBlockID.addEventListener("mouseleave", function() {
             oToolTip.classList.add('hidden');  
             oToolTip.classList.remove('visible');
        }, false)  
    });  
})(window, document);